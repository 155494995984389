import React, {useState} from "react";
import {Button, FileInput, Label, Modal, Spinner} from "flowbite-react";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import {UpscaleService} from "../service/UpscaleService";
import {t} from "i18next";

const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
});

function Upload() {
    const [isDataProcessing, setDataProcessing] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [sourceImage, setSourceImage] = useState<string | null>(null);
    const [resultImage, setResultImage] = useState<string | null>(null);

    const handleFileUpload = async (event) => {
        setDataProcessing(true)
        try {
            const upscaleService = new UpscaleService()
            const selectedFile = event.target.files[0] as File
            event.target.value = null
            setSourceImage(await toBase64(selectedFile))
            let handledImage = await upscaleService.processImage(selectedFile)
            setResultImage(handledImage)
            setOpenModal(true)
        } catch (e) {
            console.log(e)
        } finally {
            setDataProcessing(false)
        }
    };

    return (
        <section className="bg-custom-1 dark:bg-gray-900 mt-14">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
                    <div className="flex items-center">
                        <div>
                            <h1 className="flex mb-4 items-center text-5xl font-extrabold dark:text-white">{t("upload_title")}
                            </h1>
                            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">{t("upload_subtitle")}</p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center">
                        <div className="w-full p-5 m-5 opacity-75 bg-white border border-gray-200 rounded-lg shadow">
                            <Label
                                htmlFor="dropzone-file"
                                className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                {isDataProcessing ? (
                                    <Spinner aria-label="Extra large spinner example" className="w-20 h-20"/>
                                ) : (
                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                        <svg
                                            className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            {t("click_to_upload")}
                                        </p>
                                    </div>

                                )}

                                <FileInput
                                    onChange={handleFileUpload}
                                    id="dropzone-file" className="hidden"/>
                            </Label>
                        </div>
                    </div>
                </div>
            </div>

            <Modal dismissible size="3xl" show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Body>
                    <div className="space-y-6">
                        <ReactBeforeSliderComponent
                            firstImage={{
                                imageUrl: resultImage ?? ''
                            }}
                            secondImage={{
                                imageUrl: sourceImage ?? ''
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setOpenModal(false)
                        const a = document.createElement("a");
                        a.href = resultImage ?? ''
                        a.download = "ai-upscale-app.png"
                        a.click();
                    }
                    }>Download</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}


export default Upload;