import React from "react";
import {Dropdown} from "flowbite-react";
import {t} from "i18next";

function Header() {
    return (
        <nav
            className="fixed z-40 left-0 top-0 w-full h-[68px] shadow-md backdrop-filter backdrop-blur-lg bg-opacity-50 bg-white px-2 tlg:px-6 border-gray-200 dark:bg-gray-900">
            <div className="flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="/logo192.png" className="h-8" alt="Flowbite Logo"/>
                    <span
                        className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">AI-Upscale.app</span>
                </a>

                <Dropdown label={t("language")} inline>
                    <Dropdown.Item><a href="/">English</a></Dropdown.Item>
                    <Dropdown.Item><a href="/es">Español</a></Dropdown.Item>
                    <Dropdown.Item><a href="/de">German</a></Dropdown.Item>
                    <Dropdown.Item><a href="/tr">Turkish</a></Dropdown.Item>
                    <Dropdown.Item><a href="/it">Italian</a></Dropdown.Item>
                </Dropdown>
            </div>
        </nav>

    );
}

export default Header;