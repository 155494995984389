import React from "react";
import {Label} from "flowbite-react";
import {t} from "i18next";

const CallToActionItem = ({className = "", title, content, image, isImageFirst}: {
    className: string;
    title: string;
    content: string;
    image: string;
    isImageFirst: boolean
}) =>
    <div
        className={`gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-10 lg:px-6 ${className}`}>

        {isImageFirst &&
            <img className="w-full"
                 src={image}
                 alt="feature image"/>
        }


        <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{title}</h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">{content}</p>
            <Label htmlFor="dropzone-file">
                <a onClick={() => window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })}
                   className="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
                    {t("try_now")}
                    <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"></path>
                    </svg>
                </a></Label>
        </div>

        {!isImageFirst &&
            <img className="w-full"
                 src={image}
                 alt="feature image"/>
        }
    </div>


const CallToActions = () =>
    <section className="bg-white dark:bg-gray-900">
        <CallToActionItem className="" image="feat_1.png" isImageFirst={true}
                          title={t("call_to_action_1_title")}
                          content={t("call_to_action_1_subtitle")}/>

        <CallToActionItem className="hidden md:visible" image="/feat_2.png"
                          isImageFirst={false}
                          title={t("call_to_action_2_title")}
                          content={t("call_to_action_2_subtitle")}/>

        <CallToActionItem className="md:hidden" image="/feat_2.png"
                          isImageFirst={true}
                          title={t("call_to_action_2_title")}
                          content={t("call_to_action_2_subtitle")}/>

        <CallToActionItem className="" image="/feat_3.png" isImageFirst={true}
                          title={t("call_to_action_3_title")}
                          content={t("call_to_action_3_subtitle")}/>
    </section>


export default CallToActions;