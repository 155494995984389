import React from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Main from "../pages/Main";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import i18next from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18next.use(initReactI18next)
    .use(LngDetector)
    .init({
        fallbackLng: 'en',

        detection: {
            order: ["path"],
            lookupFromPathIndex: 0,
        },
        debug: true,
        resources: {
            en: {
                translation: require('../i18n/en.json'),
            },
            it: {
                translation: require('../i18n/it.json'),
            },
            es: {
                translation: require('../i18n/es.json'),
            },
            de: {
                translation: require('../i18n/de.json'),
            },
            tr: {
                translation: require('../i18n/tr.json'),
            },
        },
    });

const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation()

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return null
}

const App = () =>
    <BrowserRouter>
        <Routes>
            {["/", "/it", "/es", "/de", "/tr"].map((path, index) =>
                <Route path={path} element={<Main/>} key={index}/>
            )}
            <Route path="term-of-service" element={<Terms/>}/>
            <Route path="policy" element={<Policy/>}/>
        </Routes>
    </BrowserRouter>

export default App;
