import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

const Terms = () =>
    <>
        <Header/>
        <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
            <div className="format lg:format-lg flex justify-between px-4 mx-auto max-w-screen-xl ">
                <div className="container pt-20 sm:prose-sm">
                    <div className="prose border bg-white rounded-md p-12 sm:p-6 mx-auto">
                    </div>
                </div>

            </div>
        </main>
        <Footer/>
    </>

export default Terms;
