interface SAIUpscaleResponse {
    artifacts: {
        base64: string
    }[];
}

export class UpscaleService {
    async processImage(image: File): Promise<string> {
        const formData = new FormData()
        formData.append('image', image)
        const response = await fetch(
            `https://api.stability.ai/v1/generation/esrgan-v1-x2plus/image-to-image/upscale`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer sk-yqE2RpiNjO4MXS1UQ8RC4XZ9CuAj9nMfHNXN3U5fuLl9HGNL`,
                },
                body: formData,
            }
        )
        const body = await response.json() as SAIUpscaleResponse;

        if (!response.ok) {
            throw new Error(`Non-200 response: ${await response.text()}`)
        }

        return 'data:image/jpeg;base64,' + body.artifacts[0].base64
    }
}

