import React from "react";
import {t} from "i18next";

const HowToUse = () =>
    <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="max-w-screen-md mb-8 lg:mb-16">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{t("how_to_use_title")}</h2>
                <p className="text-gray-500 sm:text-xl dark:text-gray-400">{t("how_to_use_subtitle")}</p>
            </div>
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                <div>
                    <div className="flex justify-center items-center">
                        <img className="w-full"
                             src="/Step_1.png"
                             alt="feature image"/>

                    </div>
                    <h3 className="mb-2 text-xl font-bold dark:text-white mt-3">{t("how_to_use_step_1_title")}</h3>
                    <p className="text-gray-500 dark:text-gray-400">{t("how_to_use_step_1_subtitle")}</p>
                </div>
                <div>
                    <div className="flex justify-center items-center">
                        <img className="w-full"
                             src="/Step_2.png"
                             alt="feature image"/>

                    </div>
                    <h3 className="mb-2 text-xl font-bold dark:text-white mt-3">{t("how_to_use_step_2_title")}</h3>
                    <p className="text-gray-500 dark:text-gray-400">{t("how_to_use_step_2_subtitle")}</p>
                </div>
                <div>
                    <div className="flex justify-center items-center">
                        <img className="w-full"
                             src="/Step_3.png"
                             alt="feature image"/>
                    </div>
                    <h3 className="mb-2 text-xl font-bold dark:text-white mt-3">{t("how_to_use_step_3_title")}</h3>
                    <p className="text-gray-500 dark:text-gray-400">{t("how_to_use_step_3_subtitle")}</p>
                </div>

            </div>
        </div>
    </section>


export default HowToUse;