import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import Features from "../components/Features";
import CallToActions from "../components/CallToActions";
import HowToUse from "../components/HowToUse";
import Upload from "../components/Upload";
import Helmet from "react-helmet";
import {t} from "i18next";

const Main = () =>
    <>
        <Helmet>
            <title>{t("header_title")}</title>
            <meta name="google" content="notranslate"/>
            <meta name="description" content={t("header_description")}/>
            <meta property="og:title" content={t("header_title")}/>
            <meta property="og:site_name" content={t("header_title")}/>
            <meta property="og:description" content={t("header_description")}/>
            <meta property="twitter:title" content={t("header_title")}/>
            <meta property="twitter:description" content={t("header_description")}/>
            <meta property="twitter:twitter" content={t("header_description")}/>
            <link rel="alternate" href="https://ai-upscale.app" hrefLang="x-default"/>
            <link rel="alternate" href="https://ai-upscale.app" hrefLang="en"/>
            <link rel="alternate" href="https://ai-upscale.app/es" hrefLang="es"/>
            <link rel="alternate" href="https://ai-upscale.app/de" hrefLang="de"/>
            <link rel="alternate" href="https://ai-upscale.app/tr" hrefLang="tr"/>
            <link rel="alternate" href="https://ai-upscale.app/it" hrefLang="it"/>
        </Helmet>
        <Header/>
        <Upload/>
        <HowToUse/>
        <CallToActions/>
        <Features/>
        <FAQ/>
        <Footer/>
    </>

export default Main;
