import React from "react";
import {t} from "i18next";

function Footer() {
    return (
        <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl text-center">
                <a href="#"
                   className="flex justify-center items-center text-2xl font-semibold text-gray-900">
                    AI-Upscale.app
                </a>
                <p className="my-6 text-gray-500 dark:text-gray-400">{t("footer_title")}</p>
                <ul className="flex justify-center items-center mb-4 text-sm font-medium text-gray-500 sm:mt-0">
                    {/*<li>*/}
                    {/*    <a href="/policy" className="hover:underline me-4 md:me-6">Privacy Policy</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="/term-of-service" className="hover:underline me-4 md:me-6">Terms of service</a>*/}
                    {/*</li>*/}
                </ul>
                <span className="text-sm text-gray-500 sm:text-center">© 2024 <a href="#"
                                                                                 className="hover:underline">AI-Upscale.app</a>. All Rights Reserved.</span>
            </div>
        </footer>
    );
}

export default Footer;